main {
  .assessment {
    & { min-height: 490px; background: #FFFFFFF2; width: 95%; max-width: 925px; margin-top: 5px; }
    .steps-container { max-width: 850px; margin: 5px auto; }
    .steps {
      & { margin: 0 auto 50px; width: 98%; min-height: 380px; }
      .spacer { height: 75px; }
      
      .progress-indicator {
        & { display: flex; justify-content: center; align-items: center; line-height: 21px; margin: 15px auto 15px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 800; color: #100F2E; font-size: 18px; }
        .step-no { font-weight: 700; margin: 5px 0; } 
        .total-question-count { font-weight: 400; margin: 5px 0; }
      }

      .question { & { line-height: 36px; display: flex; justify-content: center; align-items: start; min-height: 120px; max-width: 850px; margin: 0 auto; padding: 0 10px; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 24px; }
        p { margin: 15px auto 25px; color: #343333; font-size: 24px; line-height: 31px; font-weight: 400; font-family: $primaryfont; }
      }

      .radio-container {
        & { width: 86%; margin: 0 auto; display: flex; align-items: center; min-height: 130px; }
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 18px; color: #343333; display: flex; }
          .label {
            & { cursor: pointer; width: 20%; float: left; /* position: relative; */ }
            p { display: block; /* position: absolute; margin-left: -50%; */ margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: #ED1B24; }
        }
        .MuiRadio-root { margin: 5px auto; display: flex; width: 30px; height: 30px; }
      }
      .textarea-field { & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; }
        textarea { font-family: $primaryfont; font-size: 21px; }
      }
      .slider-container {
        & { width: 86%; margin: 0 auto 120px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 20px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: #343333; font-family: $primaryfont; }
            p { display: block; font-size: 21px; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; font-weight: 400;  }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: #FC2B38; }
          .active p { font-weight: 700; color: #FC2B38; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #FC2B38; width: calc(100% + 10px); margin-left: -5px; padding: 14px 0; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #FC2B38; }

        .custom-slider .MuiSlider-thumb { background-color: #FC2B38; padding: 9px; border: 3px solid #FC2B38; }
        .custom-slider .MuiSlider-thumb:before { background-color: #FC2B38; border: 3px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #FC2B38; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
        // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .question-checkbox-container {
        & { width: 95%; max-width: 67ch; margin: 0 auto; padding-left: 4%; }
        .checkbox-label .MuiTypography-root {
          & { font-size: 18px; font-weight: 400; line-height: 21px; letter-spacing: 0px; text-align: left; font-family: $primaryfont; }
        }
        .MuiCheckbox-root { padding: 6px; }
        .MuiCheckbox-root.Mui-checked { & { color: #4298B5; }}
        .MuiCheckbox-root svg { & { height: 27px; width: 27px; }}
      }

      .slider-none-adjust { height: 35px; }
    }
    .step-buttons {
      & { display: flex; justify-content: space-between; width: 90%; max-width: 880px; margin: 30px auto 30px; padding-bottom: 45px; max-height: 50px; }
      button { color: #343333; font-weight: 400; font-size: 18px; font-family: $primaryfont; padding: 5px 5px; }
      .prev-btn { & { min-width: 50px; width: 50px; max-width: 50px; border-radius: 50%; padding: 0; }
        img { height: 100%; max-height: 50px; width: auto; }
      }
      .next-btn.Mui-disabled { background-color: #dedede; color: #6F6F6F; font-weight: 600; }
      .next-btn { background-color: #ED2028; color: #FFFFFF; font-size: 18px; line-height: 30px; font-weight: 700; text-transform: capitalize; padding: 12px 40px; max-height: 42px; border-radius: 5px; }
      .next-btn:hover { background-color: #435B63; color: #FFF; }
    }
    .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 18px; font-weight: 700; color: #ED2028; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 3ch; max-width: calc(100% - 2ch); }

    .statistics {
      & { width: 90%; max-width: 1000px; margin: 15px auto; text-align: center; }
      .chart {
        & { margin: 0 auto; padding: 35px 30px 15px; width: 90%; box-sizing: border-box; }
        // img { max-width: 95%; height: auto; }
      }
      .chart-title { margin: 0 auto 45px; padding: 15px; font-size: 18px; line-height: 21px; max-width: 60ch; }
      .statistic {
        & { position: relative; width: 100%; max-width: 650px; margin: 15px auto; padding: 15px 30px; background-color: #47466C; color: #FFF; border-radius: 10px; box-sizing: border-box; }
        .circle { position: absolute; top: -21px; left: calc(50% - 21px); width: 42px; height: 42px; background-color: #B1B0D0; border-radius: 50%; }
        .statistic-desc { font-size: 18px; line-height: 31px; font-family: $fontLato; }
      }
    }

    .progress-bar {
      & { display: flex; justify-content: space-between; margin: 0 auto; margin-top: 10px; width: 100%; }
      .bar-section { 
        .progress-percentage {  text-align: right; font-weight: 700; font-size: 15px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; }
        .progress-grid { 
          display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
          .grid-square { width: 10px; height: 10px; margin: 2px; } 
        }
        .bar { height: 6px; margin: 0 5px 10px; }
        .section-title { width: 100%; max-width: 12ch; margin: 0 auto; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 21px; line-height: 18px; }
      }

      .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #ED2028; }
      .sections { & {}
        .section-name { font-size: 18px; margin: 15px auto 5px; }
      }
    }
    .loading-container { display: flex; justify-content: center; align-items: center; min-height: 400px; }
  }
}


@media only screen and (max-width: 830px) {

  main .assessment .steps .question-checkbox-container { width: 100%; box-sizing: border-box; padding: 0 30px; }
  main .assessment .statistics .chart { padding: 30px 15px 15px; width: 95%; }
  main .assessment .statistics .chart img { max-width: 90%; height: auto; }

}


@media only screen and (max-width: 650px) {

  main .assessment .steps .question-checkbox-container { padding: 0 15px; }
  main .assessment .steps, main .assessment-additional .steps { width: 95%; padding: 0 15px; }
  main .assessment .steps .question-checkbox-container .checkbox-label .MuiTypography-root { font-size: 18px; line-height: 21px; margin: 8px 0; }

}



@media only screen and (max-width: 550px) {

  main .assessment {
    .steps {
      .question p { min-height: 160px !important; font-size: 18px; line-height: 28px; max-width: 100%; display: flex; justify-content: center; align-items: center; font-family: $fontLato; font-weight: 400; color: #343333; font-family: $primaryfont; margin-top: 0px; }
      .progress-bar .bar-section .section-title { font-size: 12px; }
      .slider-container { margin: 20px auto 90px; }
      .radio-container { margin: 20px auto 42px; min-height: auto; }
      .slider-container .slider-labels .label { width: 14%; }
      .progress-indicator { margin: 0px auto !important; font-size: 15px; line-height: 21.6px; color: #202945; }
      .progress-bar { grid-gap: 0; }
      .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
      .progress-bar .bar-section .section-title { font-size: 12px; }
      .slider-container .slider-labels { font-size: 14px; }
      .slider-container .slider-labels .label p.desktop { display: none; }
      .slider-container .slider-labels .label p.mobile { display: block; }
      .radio-container .slider-labels { font-size: 12px; }
      .radio-container .slider-labels .label p.desktop { display: none; }
      .radio-container .slider-labels .label p.mobile { display: block; }
      .multiple-choice-container .slider-labels .button-0 { grid-area: first; justify-self: center; width: 58% !important;}
      .multiple-choice-container .slider-labels .button-1 { grid-area: second; justify-self: center; width: 58% !important;}
      .multiple-choice-container .slider-labels .button-2 { grid-area: third; width: 58% !important; justify-self: center;}
      .multiple-choice-container .slider-labels { margin: auto; display: flex; flex-direction: column;  gap: 16px; grid-template-areas: "first second" "third third"; align-items: center; }
      .multiple-choice-container .slider-labels .label { color: #152036; }
    }
    .progress-bar .custom-bar .MuiLinearProgress-bar { background-color: #EB1C22; }
    .current-section { color: #EB1C22; font-size: 15px; line-height: 21.6px; font-weight: 700; font-family: $primaryfont; }
    .step-buttons { margin: 30px auto 40px; padding-bottom: 30px; }
    .step-buttons button {padding: 9px 15px; font-size: 16px; }
  }

}

.assessment {
  & { min-height: 490px; background: #fff; width: 96%; }
  .steps-container { max-width: 850px; margin: 5px auto; }
  .steps {
    & { margin: 0 auto 50px; width: 98%; min-height: 480px; }
    .spacer { height: 80px; }
    
    .progress-indicator { line-height: 21.6px; margin: 35px auto 15px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #FC2B38 ; font-size: 12px; }

    .question { & { line-height: 31px; display: flex; justify-content: center; align-items: start; min-height: 120px; max-width: 850px; margin: 0 auto; text-align: center; font-family: $primaryfont; font-weight: 400; color: $color3; font-size: 24px; }
      p { margin: 15px auto 25px; }
    }

    .radio-container {
      & { width: 86%; margin: 0 auto; display: flex; align-items: center; min-height: 130px; }
      .slider-labels {
        & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; }
        .label {
          & { cursor: pointer; width: 20%; float: left; /* position: relative; */ }
          p { display: block; /* position: absolute; margin-left: -50%; */ margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active { font-weight: 700; color: #ED1B24; }
      }
      .MuiRadio-root { margin: 5px auto; display: flex; width: 30px; height: 30px; }
    }
    .textarea-field { & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; }
      textarea { font-family: $primaryfont; font-size: 21px; }
    }
    .multiple-choice-container {
      .slider-labels {
        & { margin: auto; display: flex; align-items: center; justify-content: space-between; }
        .label {
          & { cursor: pointer;  background-color:#100F2E0D; }
          &:hover { background-color: #435B63; color: #FFF; }
          p { display: block; font-size: 18px;  text-align: center; letter-spacing: 1px; line-height: 30px;font-weight: 400; color: #152036; font-family: $primaryfont; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .label:hover p {  color: #FFF; }

        .active {
          & { font-weight: 700; background-color: #EB1C22; }
          p { color: #F6F6F6; font-weight: 700; }}
      }
    }
    .slider-container {
      & { width: 86%; margin: 0 auto 120px; }
      .slider { width: 100%; position: relative; }
      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .point {
          & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
        }
      }
      .slider-labels {
        & { width: 125%; font-weight: 400; font-size: 20px; color: #343333; }
        .label {
          & { cursor: pointer; width: 20%; float: left; position: relative; color: $color3; }
          p { display: block; font-size: 21px; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active { font-weight: 700; color: #FC2B38; }
      }

      .custom-slider.MuiSlider-root { z-index: 1000; color: #FC2B38; width: calc(100% + 10px); margin-left: -5px; padding: 14px 0; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid #FC2B38; }

      .custom-slider .MuiSlider-thumb { background-color: #FC2B38; padding: 9px; border: 3px solid #FC2B38; }
      .custom-slider .MuiSlider-thumb:before { background-color: #FC2B38; border: 3px solid white; width: 4px; height: 4px; padding: 2px; }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
      .custom-bar .MuiLinearProgress-bar { background-color: #FC2B38; top: -1px; }

      // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
      // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
      // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
    }

    .slider-none-adjust { height: 35px; }
  }
  .step-buttons {
    & { display: flex; justify-content: space-between; margin: 30px auto 30px; padding-bottom: 30px; }
    button { color: #343333; font-weight: 400; font-size: 18px; font-family: $primaryfont; padding: 12.5px 25px; }
    .next-btn { background: transparent; color: #4A4A4A; font-weight: 600; }
  }
}
