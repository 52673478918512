

.container.invite { max-width: 1156px; }

@media only screen and (max-width: 650px) {
  main .invite-container { & { font-size: 1.1rem; /* color: white; */ margin: 0 auto; }
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; }
      .invite-reasons {
        flex-wrap: wrap;
        padding: 20px 0 20px;
        
        .reason { flex: 0 1 100%; font-size: 18px; max-width: 27ch; margin: 2rem auto; }
        .reason span { /* color: $color1; */ }
        .reason img { } 
      }
    }
  }

  main .invite-container .cta-btn { & { width: 100%; max-width: 90%; } }
  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

}

.invite {
  & { min-height: 380px; width: 97%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
  h1 {font-weight: 700; font-size: 32px; line-height: 38px; text-align: center; margin: 0 auto 15px; font-family: $primaryfont; font-size: 36px; color: #27282A; padding: 45px 15px 0; }
  p {text-align: center; margin: 5px auto 10px; padding: 0 10px; font-size: 21px;  font-weight: 400; line-height: 35px; color: #202020; max-width: 52ch; font-family: "Barlow", sans-serif; }
  p:nth-child(3) { margin-bottom: 35px; }
  .form {
    & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: auto; width: 60%; }
    .invite-number { position: absolute; left: -50px; top: 50px; font-weight: bold; color: #C2C2C2; }
    .form-detail {
      & { flex: 0 1 46%; display: block; margin-bottom: 12px; font-weight: 700; }
      p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 18px; font-weight: 500; color: #4A4A4A; }
      .input-title { margin: 10px 0 0; font-weight: 700; text-transform: capitalize; font-size: 16px; font-family: $fontLato; color:#27282A; }
      .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
      .invite-field { font-family: $primaryfont !important; background-color: #F4F4F4; border-radius: 5px; }
      .invite-field .MuiInput-root { border: 1px solid transparent; padding: 5px 10px; border-radius: 5px; }
      .invite-field .MuiInput-root.Mui-focused { border: 1px solid #343333; }
      .invite-field::placeholder { font-family: $primaryfont !important; font-size: 18px !important; color: #868686; font-weight: 300; }
      .invite-field input:focus::placeholder { color: transparent; }
      .invite-field {
        input:-webkit-autofill, input:autofill { -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important; }
      }
      .error-text { margin: 5px auto 10px; color: red; font-weight: 400; font-size: 13px; }
    }      
    .delete-btn {
      & { position: absolute; right: -40px; top: 50px; }
      .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
    }
  }

  .invite-another-person {
    & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
    .invite-another { & { position: absolute; top: -82px; right: -170px; border-radius: 5px; text-align: center; width: 74px; height: 32px; }
      .add-btn { font-size: 18px; padding: 5px 5px; color: white; font-weight: 500; font-family: $fontLato; text-transform: uppercase; line-height: 22px; background-color: #435B63; margin-top: 30px;}
      .add-btn.Mui-disabled {  }
      .invite-another-icon { vertical-align: sub; }
    }
  }
  .cta-btn {
    & { display: block; margin: 10px auto 0px; text-decoration: none; height: 50px; background: #ED2028; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 13px 32px 13px; text-align: center; text-transform: uppercase; }
    &:hover { background: #435B63; transition: 0.3s; color: #fff; }
    .button-progress { margin: 4px 0 -2px; }
  }
  .cta-btn.Mui-disabled { color: #fff; background-color: #D8D8D8; }

  .include-results-confirmation {
    & { display: flex; gap: 5px; align-items: center; justify-content: center; margin: 15px auto; }
    input { width: 15px; height: 15px; border: 1px solid #979797; }
    label { display: flex; justify-content: center; align-items: center; font-weight: 400; font-size: 14px; line-height: 18.58px; color: #080808; cursor: pointer; }
  }
  hr { display: none;}
}


@media only screen and (max-width: 550px) {

  main .invite .form .invite-number { left: -25px; top: 15px; }
  main .invite { & { width: 95%; margin-top: 15px; }
    h1 { font-size: 24px; width: auto; max-width: 21ch; }
    h1 br { content: ""; }
    h1 br:after { content: " "; }
    p { margin: 5px 2% 10px; font-size: 18px; line-height: 30px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form { width: 75%; }
    .form .form-detail { flex: 0 1 100%; }
    .form .form-detail .invite-field { max-width: 100%; }
    .form .form-detail .error-text { bottom: auto; }
    .form .delete-btn { right: auto; left: -32px; top: 45px; }
    // .invite-another-person { width: 75px; }
    .invite-another-person .invite-another {
      & { top: 0px; height: 32px; width: 72px; right: -26px; left: 65px; margin-left: 0; }
      .add-btn { font-size: 18px; }
    }
    hr { width: 100%; height: 1px; background-color: #E4E4E4; display: block; }
    .buttons { width: 90%; margin-top: 30px; } 
    .cta-btn { min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 18px; }
  }

  

}

@media only screen and (max-width: 830px) {

  .invite .form { width: 80%; }

  .invite-another-person { display: none; }
  .buttons {
    & { display: flex; justify-content: space-between; align-items: center; padding: 0 20px; width: 90%; margin: 0 auto; max-width: 500px; }
    .add-btn { width: 80px;background-color: #435B63; color: #FFFFFF; font-size: 18px; font-weight: 600; border-radius: 5px; padding: 4px 8px 4px 8px; font-family: $primaryfont; align-self: center; }
    .add-btn.Mui-disabled { background-color: #D8D8D8; color: #979797; font-weight: 500; }
    .add-btn:hover { background-color: #000; color: #FFFFFF; }
  }
  .invite .cta-btn { margin: 0;}

}

