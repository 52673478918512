main {
  .intro-container { & { height: 578px; width: 95%; max-width:1110px; text-align: center; margin: 26px auto 35px; max-width: 1110px; }
    .intro { min-height: 570px; max-width: 1110px; 
      background-image: url("../../Assets/Images/intro.png"); background-size: cover; background-repeat: no-repeat; background-position: center; border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336);}
    .intro-heading { & { padding:28px 10px 10px; max-width: 719px; margin: 10px auto; }
      h1 { font-family: $primaryfont; text-align: center; font-weight:700; line-height: 40px; font-size: 32px;color:#343333; }
      span { color:#ED1B24; }
    }

    .intro-para { & { text-align: center;max-width: 668px;margin: 10px auto; }
      .para-one { font-weight: 400; font-size: 24px;line-height: 35px; color: #343333; }
      .para-two { font-weight: 400; font-size: 24px;line-height: 35px; }
    }
    .cta { & { text-align: center; }
      .cta-btn {
        & { display: block; margin: 45px auto 50px;padding: 5px 5px; text-decoration: none; width: 122px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
        &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }
}

@media only screen and (max-width: 1110px) {

  main .start .intro-container .intro {
    h1 { font-size: 30px; line-height: 34px; text-align: left; }
    .intro-points-list { font-size: 18px; }

    .completion { margin-top: 15px; }
  
  
  }
 
}
@media only screen and (max-width: 830px) {
 main {
  .start .intro-container {
    .intro { 
        & { width: 95%; box-sizing: border-box; margin: 0 auto; padding: 15px; height: auto; }
        h1 { text-align: center; width: auto; font-size: 28px; margin: 0 auto 15px; max-width: 30ch; padding-top: 30px; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        .intro-para { text-align: center; max-width: 35ch; margin: 5px auto; }
        .intro-points-list { width: auto; max-width: 34ch; margin: 15px auto; padding-left: 20px; }
        .completion { 
          & { flex-direction: column; margin: 30px auto 0 auto; }
          .time-estimate { margin: 15px auto; }
          .cta { margin-bottom: 0; }          
        }
      }
    }
  }


  .intro-container { & {}
    .intro { padding: 30px 15px; min-height: unset; height: 453px; background-image: url("../../Assets/Images/intro-bg-mobile.png"); }
    .intro-heading { & { max-width: 519px; margin:10px auto; }
      h1 { line-height: 35px;font-size: 28px; }
    }
    .intro-para { & { max-width: 468px;margin: 10px auto; }
      .para-one{ font-size: 20px;line-height: 30px; }
      .para-two{ font-size: 20px;line-height: 30px; }
    }
    .cta {
      .cta-btn { & {line-height: 22px;font-size: 18px;}
      // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }
}


@media only screen and (max-width: 1000px) and (min-width: 831px) {
  main .intro-container .intro {
    h1 { font-size: 28px; line-height: 32px; text-align: left; }
    .intro-points-list { font-size: 18px; }

    .completion { margin-top: 15px; }
  }
}

@media only screen and (max-width: 650px) {

  main .intro-container { & {}
     .intro {
      h1 { text-align: center; }
      h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
   
  }
}

@media only screen and (max-width: 550px) {

  main .intro-container .intro { & {  }
    h1 { font-size: 21px; line-height: 25px; max-width: 30ch; margin: 0 auto 15px; font-weight: 700; }
    h2 { font-size: 17px; max-width: 34ch; }
    .intro-para { font-size: 14px; line-height: 20px; max-width: 28ch; }
    .intro-points-list { font-size: 17px; font-weight: 400; line-height: 27px; max-width: 34ch; }
    .completion img { margin: 0 4px 0 0; }
  }

  main .intro-container .intro .completion { & { margin: 30px auto 0; width: 100%; justify-content: center; }
    .time-estimate { margin-bottom: 0; }
  }

  .intro-container {
    .intro-heading { max-width: 32ch; margin: 10px auto; padding: 15px 15px 0;
      h1 { line-height: 25px; font-size: 21px; }
    }
    
    .intro-para { max-width: 30ch; margin: 10px auto;
      .para-one{ font-size: 18px; line-height: 22px; margin: 0 auto 10px; }
      .para-two{ font-size: 18px; line-height: 22px; }
    }
    .cta {
      .cta-btn { & { line-height: 25px; font-size: 21px; width: auto; margin: 75px auto 45px; padding: 7px 15px; }
        // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }
}

